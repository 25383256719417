@use 'scss';
@use "scss/mixins/scrollbar.mixin" as *;

html {
  font-family: var(--sps-font-family);
  font-size: var(--sps-font-size);
  color: var(--sps-text-color);
}

body {
  @include scrollbar;
  margin: 0;


  &.no-scroll {
    overflow-y: hidden;
  }
}

main {
  flex-grow: 1;
}

.container .container {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.container.home sps-hero-slider+sps-free-text h3 {
  font-size: var(--sps-font-size-giga);
  line-height: 1.25;
}

/*** 8414040083 Multiple columns margins ***/

sps-multiple-column:has(+ sps-multiple-column) {
  margin-bottom: 0 !important;
}

@media screen and (min-width: 992px) {
  sps-multiple-column + sps-multiple-column {
    --margin-top: 0 !important;
  }
}

@media screen and (max-width: 992px) {
  sps-multiple-column + sps-multiple-column {
    margin-top: 0 !important;
  }
}
