// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1270px,
  xxl: 1400px,
) !default;

// Grid containers

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1240px,
  xxl: 1320px,
) !default;

// Typography scaling

$typo-scales: (
  body: 0.8,
  small: 1,
  button: 1,
  tag-large: 1,
  tag-regular: 1,
  mega:0.8,
  giga:0.8,
  form: 1,
  h1: 0.8,
  h2: 0.8,
  h3: 0.8,
  h4:0.8,
  h5: 1
);

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto
}

@each $key, $breakpoint in $grid-breakpoints {
  @media screen and (min-width: $breakpoint) {
    .container {
      max-width: map-get($container-max-widths, $key);
      padding: 0;

      @if($key == xs) {
        max-width: calc(100% - 1rem);
        box-sizing: border-box;
      }
    }
  }
}

@media screen and (max-width: map-get($container-max-widths, md)) {
  :root {
    @each $key, $scale in $typo-scales {
      --sps-typo-scale-#{$key}: #{$scale};
    }
  }
}


