:root {

  // Typography

  --sps-font-family: 'Frutiger';
  --sps-icon-font-family: 'Material Icons Outlined';

  --sps-font-size-small: 0.7rem;
  --sps-font-size-med: 1.5rem;
  --sps-font-size-large: 2.15rem;
  --sps-font-size-largest: 5rem;

  --sps-text-color: #000000;
  --sps-text-color-med: #666;

  --sps-font-weight: normal;
  --sps-font-weight-light: 300;
  --sps-font-weight-bold: bold;

  // Colors

  --sps-color-blue-light: #0390cc;
  --sps-color-blue: #0076a8;
  --sps-color-blue-trans: rgba(0, 118, 168, 0.5);
  --sps-color-dark-blue: #004976;
  --sps-color-black: #000;
  --sps-color-black-trans-20: rgba(0,0,0,0.2);
  --sps-color-black-trans-dark: rgba(0,0,0,0.85);

  --sps-color-white: #fff;
  --sps-color-white-trans: rgba(255,255,255,0.7);

  --sps-color-gray-light: #f6f6f6;
  --sps-color-gray-light-1: #f4f3f0;
  --sps-color-gray-light-2: #d1d1d1;
  --sps-color-gray-light-3: #979797;
  --sps-color-gray: #707070;

  --sps-color-red: #e61111;
  --sps-color-green: #99ac0f;
  --sps-color-bright-green: #0dd175;
  --sps-color-dark-green: #088249;
  --sps-color-yellow: #FFCC00;

  --sps-border-color: var(--sps-color-gray-light-1);
  --sps-border-color-darker: var(--sps-color-gray-light-2);
  --sps-backdrop-bg: var(--sps-color-black-trans-dark);

  --sps-gradient-blue: linear-gradient(135deg, var(--sps-color-blue) 0, var(--sps-color-dark-blue) 100%);
  --sps-gradient-green: linear-gradient(135deg, var(--sps-color-bright-green) 0, var(--sps-color-dark-green) 100%);

  // Spacings

  --sps-max-viewport: 1920px;
  --sps-default-spacing: 1rem;
  --sps-grid-gap: 1rem;
  --sps-border-radius: 0;
  --sps-border-radius-scrollbar: 6px;
  --sps-vw: calc(100vw - var(--sps-scrollbar-width));
  --sps-page-margin: calc(0.5 * (var(--sps-vw) - var(--sps-container-width)));

  // Cards

  --sps-min-card-width: 12rem;
  --sps-max-card-width: 17rem;
  --sps-card-aspect-ratio: 2/3;

  // Powerbar

  --sps-powerbar-min-width: 18ch;
  --sps-powerbar-margin-top: 0.5rem;
  --sps-powerbar-height: 0.172em;

  // Misc

  --sps-icon-fill: 0;
  --sps-box-shadow-spread: 0.8rem;
  --sps-box-shadow: 0 0 var(--sps-box-shadow-spread) 0 rgba(0, 0, 0, 0.2);
  --sps-box-shadow-lighter: 0 0 var(--sps-box-shadow-spread) 0 rgba(0, 0, 0, 0.1);
  --sps-box-shadow-darker: 0 0 0.8rem 0 rgba(0, 0, 0, 0.3);
  --sps-default-duration: 0.2s;

  --sps-media-aspect-ratio: 1.77 / 1;
  --sps-top-nav-height: 2.75rem;
  --sps-max-z-index: 999;
}
